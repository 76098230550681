@font-face {
  font-family: Outfit-Variable;
  src: url("Outfit-Variable.d6bd3e37.woff2") format("woff2"), url("Outfit-Variable.f3884515.woff") format("woff"), url("Outfit-Variable.ce6857cf.ttf") format("truetype");
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Outfit-Thin;
  src: url("Outfit-Thin.caaa0b23.woff2") format("woff2"), url("Outfit-Thin.86ee08a5.woff") format("woff"), url("Outfit-Thin.ef123354.ttf") format("truetype");
  font-weight: 100;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Outfit-ExtraLight;
  src: url("Outfit-ExtraLight.4699fccc.woff2") format("woff2"), url("Outfit-ExtraLight.05219e5b.woff") format("woff"), url("Outfit-ExtraLight.b8d97810.ttf") format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Outfit-Light;
  src: url("Outfit-Light.70ac0624.woff2") format("woff2"), url("Outfit-Light.b366ee90.woff") format("woff"), url("Outfit-Light.53bd9680.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Outfit-Regular;
  src: url("Outfit-Regular.471b3030.woff2") format("woff2"), url("Outfit-Regular.691250a8.woff") format("woff"), url("Outfit-Regular.a31fe1f0.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Outfit-Medium;
  src: url("Outfit-Medium.bc459bd8.woff2") format("woff2"), url("Outfit-Medium.c590bbdf.woff") format("woff"), url("Outfit-Medium.a36bd427.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Outfit-SemiBold;
  src: url("Outfit-SemiBold.5f0f3849.woff2") format("woff2"), url("Outfit-SemiBold.50e0f481.woff") format("woff"), url("Outfit-SemiBold.c5e31ea0.ttf") format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Outfit-Bold;
  src: url("Outfit-Bold.401d737b.woff2") format("woff2"), url("Outfit-Bold.a801bd77.woff") format("woff"), url("Outfit-Bold.031f1e75.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Outfit-ExtraBold;
  src: url("Outfit-ExtraBold.6c407d14.woff2") format("woff2"), url("Outfit-ExtraBold.a406c5f7.woff") format("woff"), url("Outfit-ExtraBold.525bb2bf.ttf") format("truetype");
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Outfit-Black;
  src: url("Outfit-Black.72e84296.woff2") format("woff2"), url("Outfit-Black.a86f708e.woff") format("woff"), url("Outfit-Black.089c16ae.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

/*# sourceMappingURL=index.21775aba.css.map */
